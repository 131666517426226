import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../components/containers/PageContainer';
import { Column } from '../../components/table/DataTable';
import { theme } from '../../assets/styles/theme';
import { messageService } from '../../services/Message/messageService';
import { MESSAGES_TAB_ONGOING_PATH } from '../../providers/messages/messagesPaths';
import { FundraiserDetailsDto } from '../../services/User/userService.dto';
import { userService } from '../../services/User/userService';
import { TableFilter } from '../../components/table/FiltersPanel';
import DataTableWithFilters from '../../components/table/DataTableWithFilters';
import { DataTableRef } from '../../components/table/dataTableRef';
import { dateUtils } from '../../utils/dateUtils';
import { phoneUtils } from '../../utils/phoneUtils';
import CellWithPopover from '../../components/table/CellWithPopover';
import { useError } from '../../providers/useError';
import FundraiserVerificationPopover from './components/FundraiserVerificationPopover';
import FundraiserActions from './components/FundraiserActions';
import UserDetailsCard from './components/UserDetailsCard';
import { useFundraiserFilters } from './hooks/useFundraiserFilters';
import { useUpdateFundraiserPage } from './hooks/useUpdateFundraiserPage';
import SubdomainCell from './components/SubdomainCell';

const Container = styled.div`
  padding: 0 ${theme.paddingStandard}px;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const Fundraisers = () => {
  const { t } = useTranslation('users');
  const [selectedFundraiser, setSelectedFundraiser] = useState<FundraiserDetailsDto>();
  const [verificationPopoverAnchor, setVerificationPopoverAnchor] = useState<HTMLDivElement | null>(null);
  const dataTableRef = useRef<DataTableRef>(null);
  const navigate = useNavigate();
  const { handleError } = useError();
  const { showUpdateFundraiserPagePopover } = useUpdateFundraiserPage(() => dataTableRef.current?.refresh());

  const { onTableFiltered, filterInitState } = useFundraiserFilters();

  const columns: Column<FundraiserDetailsDto>[] = [
    {
      id: 'id',
      title: t('fundraisers-columns.id'),
      isSortable: true,
      align: 'center',
      renderCell: data => <CellWithPopover popover={<UserDetailsCard userId={data.id} />}>{data.id}</CellWithPopover>,
    },
    {
      id: 'email',
      title: t('fundraisers-columns.email'),
      isSortable: true,
      minWidth: 100,
      maxWidth: 200,
      renderCell: data => data.email,
    },
    {
      id: 'firstName',
      title: t('fundraisers-columns.first-name'),
      minWidth: 100,
      maxWidth: 200,
      isSortable: true,
      renderCell: data => data.firstName,
    },
    {
      id: 'lastName',
      title: t('fundraisers-columns.last-name'),
      minWidth: 100,
      maxWidth: 200,
      isSortable: true,
      renderCell: data => data.lastName,
    },
    {
      id: 'fundraiserVerifiedByAdmin',
      title: t('fundraisers-columns.verified-by-admin'),
      renderCell: data => (data.fundraiserVerificationCompleted ? t('common:yes') : t('common:no')),
    },
    {
      id: 'phoneNumber',
      title: t('fundraisers-columns.phone-number'),
      renderCell: data => phoneUtils.convertToPhoneDisplayNumber(data.phone),
    },
    {
      id: 'fundraiserType',
      title: t('fundraisers-columns.fundraiser-type'),
      renderCell: data => data.fundraiserType,
    },
    {
      id: 'organizationName',
      title: t('fundraisers-columns.organization-name'),
      isSortable: true,
      renderCell: data => data.organizationName,
    },
    {
      id: 'ein',
      title: t('fundraisers-columns.ein'),
      isSortable: true,
      renderCell: data => data.ein,
    },
    {
      id: 'address',
      title: t('fundraisers-columns.address'),
      minWidth: 180,
      renderCell: data =>
        `${data.addressLine1}${data.addressLine2 ? ` ${data.addressLine2}` : ''}, ${data.city}, ${data.state}, ${
          data.country
        }`,
    },
    {
      id: 'inAppVerificationDate',
      title: t('fundraisers-columns.in-app-verification-date'),
      isSortable: true,
      renderCell: data => dateUtils.toLocalDateTimeString(data.inAppVerificationDate),
    },
    {
      id: 'adminVerificationDate',
      title: t('fundraisers-columns.admin-verification-date'),
      isSortable: true,
      renderCell: data =>
        data.adminVerificationDate ? dateUtils.toLocalDateTimeString(data.adminVerificationDate) : undefined,
    },
    {
      id: 'verifyingAdminId',
      title: t('fundraisers-columns.verifying-admin-id'),
      renderCell: data => data.verifyingAdminId,
    },
    {
      id: 'subdomainName',
      title: t('fundraisers-columns.subdomain-name'),
      minWidth: 120,
      renderCell: data => (
        <SubdomainCell fundraiser={data} onEditPressed={() => showUpdateFundraiserPagePopover(data)} />
      ),
    },
    {
      id: 'actions',
      title: t('fundraisers-columns.actions'),
      align: 'center',
      minWidth: 60,
      renderCell: data => {
        return (
          <FundraiserActions
            fundraiser={data}
            onContactUser={() => openConversation(data.id)}
            onVerify={(e, fundraiser) => displayVerificationPopover(e, fundraiser)}
          />
        );
      },
    },
  ];

  const filters: TableFilter[] = [
    { type: 'numeric', name: 'id', label: t('fundraisers-filters.id') },
    { type: 'text', name: 'email', label: t('fundraisers-filters.email') },
    { type: 'text', name: 'firstName', label: t('fundraisers-filters.first-name') },
    { type: 'text', name: 'lastName', label: t('fundraisers-filters.last-name') },
    {
      type: 'dropdown',
      name: 'fundraiserVerificationCompleted',
      label: t('fundraisers-filters.fundraiser-verified-by-admin'),
      options: [t('common:true'), t('common:false')],
    },
  ];

  const openConversation = (endUserId: number) =>
    messageService
      .createConversation(endUserId)
      .then(({ data }) => navigate(`${MESSAGES_TAB_ONGOING_PATH}/${data.id}`));

  const completeFundraiserVerification = (userId: number) => {
    userService
      .verifyFundraiser(userId)
      .then(() => {
        closeVerificationPopover();
        dataTableRef.current?.refresh();
      })
      .catch(error => handleError(error));
  };

  const displayVerificationPopover = (element: HTMLDivElement, content: FundraiserDetailsDto) => {
    setSelectedFundraiser(content);
    setVerificationPopoverAnchor(element);
  };

  const closeVerificationPopover = () => {
    setVerificationPopoverAnchor(null);
    setSelectedFundraiser(undefined);
  };

  return (
    <PageContainer>
      <Container>
        <DataTableWithFilters
          ref={dataTableRef}
          columns={columns}
          filters={filters}
          onTableStateChanged={onTableFiltered}
          filterInitState={filterInitState}
        />
      </Container>
      {verificationPopoverAnchor && selectedFundraiser && (
        <FundraiserVerificationPopover
          fundraiser={selectedFundraiser}
          anchor={verificationPopoverAnchor}
          onConfirmPressed={completeFundraiserVerification}
          onClosePressed={closeVerificationPopover}
        />
      )}
    </PageContainer>
  );
};

export default Fundraisers;
